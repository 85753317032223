<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">โอน ({{ product.productName }})</div>
      </template>
      <div class="d-block">
        <InputText
          v-model="form.amount"
          textFloat="โอนจำนวน"
          placeholder="จำนวน"
          type="text"
          name="transfer_amount"
          className="mb-2"
          isRequired
          :textDesc="`จำนวนปัจจุบัน : ${product.currentStock}`"
          :isValidate="$v.form.amount.$error"
          :v="$v.form.amount"
        />
        <InputSelect
          title="สาขาปลายทาง"
          isRequired
          :options="branchList"
          v-model="form.toBranchId"
          @onDataChange="onDataChange"
          :isValidate="$v.form.toBranchId.$error"
          :v="$v.form.toBranchId"
          textField="branch_name"
          valueField="branch_id"
          className="mb-0"
        />
        <div class="border-top panel-display-count mt-3" v-if="form.toBranchId > 0">
          <div class="d-flex justify-content-between mb-2">
            <p class="label">จำนวนสุทธิ (สาขาปลายทาง)</p>
            <p>
              จำนวนปัจจุบัน :
              {{ branchToSelect.current_stock | numeral("0,0") }}
            </p>
          </div>
          <p>
            {{
              (parseInt(form.amount) + parseInt(branchToSelect.current_stock))
                | numeral("0,0")
            }}
          </p>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    InputSelect,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { amount: 0, toBranchId: 0 },
      branchList: [],
      product: {
        id: 0,
        productName: "",
        inventoryId: 0,
        currentStock: 0,
        branchId: 0,
      },
      fromModal: "",
    };
  },
  validations() {
    return {
      form: {
        amount: {
          required,
          minValue: minValue(1),
          handleMaxTransfer: (value) => {
            return this.handleMaxTransfer(value);
          },
        },
        toBranchId: { required, minValue: minValue(1) },
      },
    };
  },
  computed: {
    branchToSelect() {
      let branch = this.branchList.filter(
        (item) => item.branch_id == this.form.toBranchId
      );
      return branch.length ? branch[0] : null;
    },
  },
  methods: {
    async show(item, fromModal = "") {
      this.form.amount = 0;
      this.form.toBranchId = 0;

      this.$v.form.$reset();
      this.product.id = item.product_id;
      this.product.productName = item.name;
      this.product.inventoryId = item.id;
      this.product.currentStock = item.stock;
      this.product.branchId = item.branch_id;

      await this.getOtherBranchInventory(this.product.id);

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleMaxTransfer(val) {
      return parseInt(val) <= parseInt(this.product.currentStock);
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = {
        transfer_amount: this.form.amount,
        from_inventory_id: this.product.inventoryId,
        to_branch_id: this.form.toBranchId,
        to_product_id: this.product.id,
      };
      await this.axios
        .post(`${this.$baseUrl}/Inventory/TransferProduct`, body)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            if (this.fromModal == "otherBranch") {
              this.$emit("reloadOtherBranch");
            } else {
              this.$emit("reloadDataTable");
            }
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
      this.isLoading = false;
    },
    async onDataChange(val) {
      this.form.toBranchId = val;
    },
    async getOtherBranchInventory(productId) {
      let branchList = [{ branch_id: 0, branch_name: "กรุณาเลือกสาขาปลายทาง" }];
      let filtered = [];
      await this.axios
        .get(`${this.$baseUrl}/Inventory/GetBranchs/${productId}`)
        .then((data) => {
          if (data.result == 1) {
            filtered = data.detail.filter(
              (item) => item.branch_id != this.branchId
            );
          }
        });
      this.branchList = branchList.concat(filtered);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.panel-display-count {
  padding-top: 10px;
  p.label {
    color: #333 !important;
    font-weight: bold !important;
  }
  p {
    color: #999 !important;
    font-size: 14px !important;
  }
}
</style>
