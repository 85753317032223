<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">จัดการคลัง</div>
      </template>
      <div class="d-block">
        <b-tabs v-model="tabIndex" fill>
          <b-tab title="เพิ่ม" active class="pt-2">
            <InputText
              v-model="form.amount"
              textFloat="จำนวน"
              placeholder="จำนวน"
              type="text"
              name="transfer_amount"
              className="mb-2"
              isRequired
              :textDesc="`จำนวนปัจจุบัน : ${product.currentStock}`"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
              @onKeypress="isNumber($event)"
            />
            <InputText
              v-model="avilable"
              textFloat="คงเหลือ"
              type="text"
              name="transfer_avilable"
              className="mb-2"
              disabled
            />
            <InputTextArea
              textFloat="หมายเหตุ"
              placeholder=""
              name="text"
              rows="5"
              v-model="form.note"
            />
          </b-tab>
          <b-tab title="ลด" class="pt-2"
            ><InputText
              v-model="form.amount"
              textFloat="จำนวน"
              placeholder="จำนวน"
              type="text"
              name="transfer_amount"
              className="mb-2"
              isRequired
              :textDesc="`จำนวนปัจจุบัน : ${product.currentStock}`"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
              @onKeypress="isNumber($event)" />
            <InputText
              v-model="avilable"
              textFloat="คงเหลือ"
              type="text"
              name="transfer_avilable"
              className="mb-2"
              disabled />
            <InputTextArea
              textFloat="หมายเหตุ"
              placeholder=""
              name="text"
              rows="5"
              v-model="form.note"
          /></b-tab>
          <b-tab title="ปรับ" class="pt-2"
            ><InputText
              v-model="form.amount"
              textFloat="จำนวน"
              placeholder="จำนวน"
              type="text"
              name="transfer_amount"
              className="mb-2"
              isRequired
              :textDesc="`จำนวนปัจจุบัน : ${product.currentStock}`"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
              @onKeypress="isNumber($event)" />

            <InputTextArea
              textFloat="หมายเหตุ"
              placeholder=""
              name="text"
              rows="8"
              v-model="form.note"
          /></b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from '@/components/inputs/InputText';
import InputSelect from '@/components/inputs/InputSelect';
import InputTextArea from '@/components/inputs/InputTextArea';
import { required, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    InputText,
    InputSelect,
    InputTextArea
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { amount: 0, avilable: 0, note: '', currentStock: 0 },
      branchList: [],
      product: {
        id: 0,
        productName: '',
        inventoryId: 0,
        currentStock: 0,
        branchId: 0
      },
      fromModal: '',
      tabIndex: 0
    };
  },
  validations() {
    return {
      form: {
        amount: {
          required,
          minValue: minValue(1),
          handleMaxTransfer: value => {
            return this.handleMaxTransfer(value);
          }
        }
      }
    };
  },
  computed: {
    avilable() {
      let avilable = this.product.currentStock;
      let amount = parseInt(this.form.amount);
      let currentStock = parseInt(this.product.currentStock);
      if (this.tabIndex === 0) {
        avilable = currentStock + amount;
      } else if (this.tabIndex === 1) {
        avilable = currentStock - amount;
      }

      this.form.avilable = avilable;
      return avilable;
    }
  },
  methods: {
    async show(item, fromModal = '') {
      this.form.amount = 0;
      this.form.toBranchId = 0;
      this.$v.form.$reset();
      this.product.id = item.product_id;
      this.product.productName = item.name;
      this.product.inventoryId = item.id;
      this.product.currentStock = item.stock;
      this.product.branchId = item.branch_id;
      this.form.avilable = this.product.currentStock;

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleMaxTransfer(val) {
      if (this.tabIndex == 1)
        return parseInt(val) <= parseInt(this.product.currentStock);
      return true;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      let body = {
        // avilable: this.form.avilable,
        edit_type: this.tabIndex + 1,
        inventory_id: this.product.inventoryId,
        amount: this.form.amount,
        note: this.form.note,
        product_id: this.product.id,
        stock: this.product.currentStock,
        branch_id: this.branchId
      };

      await this.$store.dispatch('setMainLoading', true);
      await this.axios
        .post(`${this.$baseUrl}/Inventory/UpdateInventory`, body)
        .then(async data => {
          await this.$store.dispatch('setMainLoading', false);
          if (data.result == 1) {
            this.successAlert();
            this.$emit('reloadDataTable');
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
</style>
