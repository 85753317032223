<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="จัดการสินค้า"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-option>
          <b-col>
            <InputText
              v-model="filter.search"
              textFloat="ชื่อสินค้า / Barcode"
              placeholder="ชื่อสินค้า / Barcode"
              type="text"
              name="inventory_search"
              @onDataChange="handleSearch"
              class="mb-2"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(price)="data">
                <div>{{ data.item.price | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(stock)="data">
                <div>{{ data.item.stock | numeral('0,0') }}</div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="@/assets/images/icons/adjust.png"
                    alt="adjust"
                    class="action-img pointer"
                    center
                    @click="$refs.modalManage.show(data.item)"
                  />
                  <font-awesome-icon
                    icon="exclamation-circle"
                    class="info pointer"
                    @click="$refs.modalOtherBranch.show(data.item)"
                  />
                  <img
                    src="@/assets/images/icons/transfer.png"
                    alt="transfer"
                    class="action-img pointer"
                    center
                    @click="$refs.modalTransfer.show(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalTransfer
      ref="modalTransfer"
      @reloadDataTable="getList"
      :branchId="branchId"
      @reloadOtherBranch="reloadOtherBranch"
    />
    <ModalManage
      ref="modalManage"
      @reloadDataTable="getList"
      :branchId="branchId"
    />
    <ModalOtherBranch
      ref="modalOtherBranch"
      @reloadDataTable="getList"
      :branchId="branchId"
      @handleTransfer="handleTransfer"
    />
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputText from '@/components/inputs/InputText';
import ModalManage from '@/components/inventory/ModalManage';
import ModalOtherBranch from '@/components/inventory/ModalOtherBranch';
import ModalTransfer from '@/components/inventory/ModalTransfer';
import { mapGetters } from 'vuex';

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputText,
    ModalManage,
    ModalOtherBranch,
    ModalTransfer
  },
  name: 'Inventory',
  data() {
    return {
      fields: [
        {
          key: 'barcode',
          label: 'Barcode',
          thStyle: { width: '15%' }
        },
        {
          key: 'name',
          label: 'สินค้า',
          thStyle: { width: '35%' }
        },

        {
          key: 'article_no',
          label: 'Article No.'
        },
        {
          key: 'price',
          label: 'ราคา'
        },
        {
          key: 'stock',
          label: 'คงเหลือ'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'text-center'
        }
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxActive: false,
      checkboxInactive: false,
      filter: {
        branch_id: this.branchId,
        search: '',
        page: 1,
        take : 10
      },
      pageOptions: [
        { value: 5, text: '5 / page' },
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      selected: 0,
      isLoading: true,
      styleDatetime: {
        width: '100%',
        border: 'none'
      }
    };
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.filter.branch_id = this.branchId;
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/Inventory/GetInventory`, payload)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail.data;
            this.rows = data.detail.count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    handleSearch(value) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit('filterPage', this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit('filterPage', this.filter);
      this.getList();
    },
    clearFilter() {
      this.filter = {
        search: '',
        page: 1,
        take : 10,
        branch_id: this.branchId
      };
      this.getList();
    },
    handleTransfer(item) {
      this.$refs.modalTransfer.show(item, 'otherBranch');
    },
    reloadOtherBranch() {
      this.$refs.modalOtherBranch.getOtherBranchInventory();
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.info {
  margin: 0 5px;
  font-size: 24px;
  color: #000 !important;
}

::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
</style>
